<template>
  <div>
    <s-crud
      title="Lista de proveedores aprobados"
      pdf
      @pdf="pdf()"
      excel
      @excel="excel()"
      :config="config"
      :filter="filter">

      <template v-slot:filter>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" lg="6">
              <s-select-definition
              :def="1173"
              v-model="filter.TypeCultive"
              label="Tipo Cultivo">

              </s-select-definition>
            </v-col>
          </v-row>
        </v-container>
      </template>

    </s-crud>
  </div>
</template>


<script>


import _sApprovedProviders from "@/services/Technicalassistance/TasApprovedProvidersService";


export default {
  props: {},
  component:{},
  data: () => ({
    filter: {TypeCultive: 0},
    config: {
      service:_sApprovedProviders,
      model: {
        //Alias: "ID",
        VigencialDel: "date",
        VigenciaAl : "date",
      },
      headers: [
        {text:"Proveedor", value:"Proveedor"},
        {text:"Nombre fundo", value:"NombreFundo"},
        // {text:"Alias", value:"Alias"},
        {text:"Tipo de aprobación", value:"TipoAprobacion"},
        {text:"Vigencia Del", value:"VigencialDel"},
        {text:"vigencia Al", value:"VigenciaAl"}
      ],
    },
  }),

  methods: {

    pdf(){
      _sApprovedProviders.pdf(this.filter, this.$fun.getUserID())
      .then((r)=>{
        this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF,
        "PROVEEDORES APROBADOS DE MATERIA PRIMA")
      });
    },
    excel() {
      _sApprovedProviders.excel(this.filter)
      .then((r)=>{
        this.$fun.downloadFile(r.data,this.$const.TypeFile.EXCEL, 
        "PROVEEDORES APROBADOS DE MATERIA PRIMA");
      });
    }

  },
}
</script>