import Service from "../Service";
const resource = "approvedproviders/";

export default {

    pagination(parameters, requestID) {
      
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    excel(dtr, requestID) {
        return Service.post(resource + "excel", dtr,{
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {}
        });
    },

    pdf(obj, requestID) {
        return Service.post(resource + "pdf", obj,{
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: {requestID}
        });
    },
 
};